/**
 * @author: https://www.51xuediannao.com/
 * @update: 2021-09-23 12:02
 */
const _CONFIG = Object.assign({
    "debug": true,
    "host": process.env.VUE_APP_HOST,
    "apiPath": process.env.VUE_APP_API,
    "api":{
        "boss":process.env.VUE_APP_API+"/boss",
		"www":process.env.VUE_APP_API+"/www",
    },
    "apiOSSPath":"https://sharing-activities.oss-cn-beijing.aliyuncs.com/",
    "siteUrl": process.env.VUE_APP_HOST,
    "cdn": "https://sharing-activities.oss-cn-beijing.aliyuncs.com/",
    "rootPath": "",
    "siteName": "不良产品处理系统",
    "systemFullName": "不良产品处理系统",
    "shortName": "不良产品处理系统",
    "column": [],
    "prefix":"LPF-MN-BOSS",
}, {});

export {_CONFIG}